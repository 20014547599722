import { useContext, useEffect } from "react";
import {
  fieldValue,
  firebase,
  firestore,
} from "services/firebaseService/firebaseConfig";
import { useSelector } from "react-redux";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectUserId } from "modules/auth/redux/selectors";
import logger from "logging/logger";
import { useAppDispatch } from "store/hooks";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Actions, trackAction } from "modules/monitoring";
import { selectIsMobile } from "modules/deviceInfo";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { EmojiReactions, IEmojiReactionPayload } from "../../types";
import { addConversationEmoji } from "../../../redux";
import { ConversationEmoji } from "../../../redux/slice";
import { QUERY_CUTOFF_TIME } from "../../constants";
import { scheduleAddReaction } from "../../redux/actions";

export const ReactionEffects = () => {
  const isMobileView = useSelector(selectIsMobile);
  const { serverTime } = useSystemClock();
  const eventId = useSelector(selectCurrentEventId);
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);

  useEffect(() => {
    if (!eventId || isMobileView) return;

    const unsubscribe = firestore
      .collection("EmojiReactions")
      .doc(eventId)
      .collection("reactions")
      .where(
        "time",
        ">",
        firebase.firestore.Timestamp.fromMillis(serverTime - QUERY_CUTOFF_TIME),
      )
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (isInBroadcast) {
              dispatch(
                scheduleAddReaction(change.doc.data() as IEmojiReactionPayload),
              );
            } else {
              dispatch(
                addConversationEmoji(change.doc.data() as ConversationEmoji),
              );
            }
          }
        });
      });

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // serverTime is not needed in dependencies:
    // this query is registered once and callback runs on every new child
    eventId,
    isMobileView,
    isInBroadcast,
  ]);

  return null;
};

const useEmojiReactions = () => {
  const userId = useSelector(selectUserId);
  const eventId = useSelector(selectCurrentEventId);
  const { track } = useContext(TRACKING_CONTEXT);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const addReaction = async (reactionType: EmojiReactions) => {
    if (!userId || !eventId) return;

    track(Events.AUDIENCE_REACTION_CLICKED, {
      reaction: reactionType,
      eventId,
      userId,
      isPresentationMode: Boolean(isInBroadcast),
    });

    trackAction(
      isInBroadcast
        ? Actions.PRESENTATION_MODE_AUDIENCE_REACTION_CLICKED
        : Actions.CONVERSATION_MODE_AUDIENCE_REACTION_CLICKED,
      {
        reaction: reactionType,
        eventId,
        userId,
      },
    );

    try {
      await firestore
        .collection("EmojiReactions")
        .doc(eventId)
        .collection("reactions")
        .add({
          time: fieldValue.serverTimestamp(),
          reactionType,
          id: btoa(Date.now() + userId),
          userId,
          isPresentationMode: Boolean(isInBroadcast),
        });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "";
      logger.error(
        `[useEmojiReactions][addEmojiReaction] err: ${errorMessage} event: ${eventId}`,
      );
    }
  };

  return {
    addReaction,
  };
};

export default useEmojiReactions;
