import { Middleware } from "@reduxjs/toolkit";
import { selectUserId } from "modules/auth/redux/selectors";
import { playFloatingReaction } from "./playFloatingReaction";
import ActionTypes from "../redux/constants";
import { createDelayingQueue } from "../createDelayingQueue";
import {
  FLOATING_REACTION_INTERVAL,
  MAX_SIMULTANEOUS_FLOATING_REACTIONS,
} from "../constants";

export const floatingReactionsQueue = createDelayingQueue(
  MAX_SIMULTANEOUS_FLOATING_REACTIONS,
  FLOATING_REACTION_INTERVAL,
);

export const reactionsMiddleware: Middleware =
  (store) =>
  (next) =>
  (
    action,
    // eslint-disable-next-line consistent-return
  ) => {
    if (action.type !== ActionTypes.SCHEDULE_ADD_REACTION) return next(action);

    // eslint-disable-next-line consistent-return
    if (!document.hasFocus()) return;

    const currentUserId = selectUserId(store.getState());
    const {
      reaction: { reactionType, userId },
    } = action.payload;

    if (userId === currentUserId) {
      playFloatingReaction(reactionType);
    } else {
      floatingReactionsQueue.enqueue(() => playFloatingReaction(reactionType));
    }
  };
